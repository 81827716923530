import {
  Component, Inject, NgZone, OnDestroy, OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest, Observable, of, Subject, throwError,
} from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { getCurrencySymbol } from '@angular/common';
import {
  catchError, map, takeUntil,
} from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { I18NEXT_SERVICE, ITranslationService } from 'angular-i18next';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  BillingDetail,
  MerchantData,
  PaymentTransaction, SubscriptionRecalculationInfo,
  UserCard,
} from '@app/graphql/graphql';
import { BillingService } from '@app/gql-service/billing.service';
import { LoadingService } from '@app/loading/loading.service';
import { ROUTE_URL } from '@app/router-url.constants';
import { PaymentMethodService } from '@app/user-card/payment-method.service';
import { BillingDetailService } from '@app/gql-service/billing-detail.service';
import { TranslationUtilService } from '@app/util/translations/translation-util.service';
import { IpAddressService } from '@app/login-page/ip-address.service';
import { parseIfValidJson } from '@app/util/json.util';

const INITIAL_STEP_INDEX = 0;
const PAYMENT_METHOD_STEP_INDEX = 1;

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
  providers: [LoadingService],
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  private readonly REDIRECT_DELAY = 2000;

  private formGroup: FormGroup;

  private billingDetails: BillingDetail[] = [];

  merchantData$: Observable<MerchantData>;

  paymentTransaction$: Observable<PaymentTransaction>;

  disclaimer$: Observable<string>;

  formParams = {
    hideCvvNumbers: true,
    formTypeClass: 'default',
    hideEmail: true,
  };

  formStyles = {
    submit_button: {
      'background-color': '#1890FF',
      height: '50px',
      ':disabled': {
        'background-color': '#576574',
      },
    },
  };

  transactionDetails$: Observable<string>;

  companyPaymentCard$: Observable<UserCard>;

  defaultCardProviderPath = 'user-card.svg';

  isExistingCardActive = false;

  isNewCardActive = false;

  isFormShown = true;

  showCardManagingBlock$: Observable<boolean>;

  currentStepIndex: number = INITIAL_STEP_INDEX;

  hasAllBillingDetails = false;

  recalculationInfo: SubscriptionRecalculationInfo;

  switchMessage: Observable<string>;

  private componentIsDestroyed = new Subject<boolean>();

  private paymentId: string;

  constructor(
    public paymentMethodService: PaymentMethodService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private billingService: BillingService,
    private loadingService: LoadingService,
    private billingDetailService: BillingDetailService,
    @Inject(I18NEXT_SERVICE)
    private translationService: ITranslationService,
    private nzMessageService: NzMessageService,
    private translationUtilService: TranslationUtilService,
    private ipAddressService: IpAddressService,
    private ngZone: NgZone,
  ) {
  }

  ngOnInit(): void {
    this.billingDetailService.hasAllBillingDetails()
      .pipe(takeUntil(this.componentIsDestroyed))
      .subscribe((hasAllBillingDetails) => {
        this.hasAllBillingDetails = hasAllBillingDetails;
        if (this.hasAllBillingDetails) {
          this.currentStepIndex = PAYMENT_METHOD_STEP_INDEX;
        }
      });

    const operatorIp$ = this.ipAddressService.getIPAddress();

    combineLatest([
      this.activatedRoute.params,
      operatorIp$,
    ], (params, ip) => {
      this.paymentId = params.id;
      this.merchantData$ = this.loadingService.showLoaderUntilCompleted(
        this.billingService.generateMerchantData(params.id, { operatorIp: ip })
          .pipe(
            catchError((err) => {
              const errorJson = parseIfValidJson(err?.message);

              if (errorJson?.status === HttpStatusCode.PreconditionFailed) {
                this.router.navigate([ROUTE_URL.settingsPlans]);
                this.nzMessageService.error(
                  this.translationService.t(errorJson?.value),
                );

                return throwError(err);
              }

              this.router.navigate([ROUTE_URL.pageNotFound]);

              return throwError(err);
            }),
          ),
      );
      this.billingService
        .getSubscriptionRecalculationInfo(this.paymentId)
        .pipe(takeUntil(this.componentIsDestroyed))
        .subscribe((value) => {
          this.recalculationInfo = value;
        });

      this.paymentTransaction$ = this.billingService
        .getPaymentTransaction(this.paymentId);
      this.switchMessage = this.getSubscriptionChangingMessage(
        this.billingService.getSubscriptionRecalculationInfo(
          this.paymentId,
        ),
      );
      this.companyPaymentCard$ = this.billingService
        .getCompanyPaymentCard();
      this.formDisclaimer(this.paymentTransaction$);
      this.formTransactionDetails(this.paymentTransaction$);
      this.formShowCardManagingBlock(
        this.paymentTransaction$,
        this.companyPaymentCard$,
      );
    }).pipe(takeUntil(this.componentIsDestroyed))
      .subscribe();
  }

  success() {
    this.redirectAfterPayment(`${ROUTE_URL.payment}/${this.paymentId}${ROUTE_URL.successful}`);
  }

  fail() {
    this.redirectAfterPayment(`${ROUTE_URL.payment}/${this.paymentId}${ROUTE_URL.failed}`);
  }

  redirectAfterPayment(route: string) {
    this.loadingService.showLoaderUntilCompleted(
      new Observable<any>(() => {
        setTimeout(() => {
          this.isFormShown = false;
          this.ngZone.run(() => {
            this.router.navigate([route]);
          });
        }, this.REDIRECT_DELAY);
      }),
    ).pipe(takeUntil(this.componentIsDestroyed))
      .subscribe();
  }

  closePayment() {
    this.router.navigate([ROUTE_URL.settingsBilling]);
  }

  getCurrencySymbol(code: string): string {
    return getCurrencySymbol(code, 'narrow');
  }

  payInOneClick(paymentId: string) {
    const oneClickPayResult$ = this.billingService.payInOneClick(paymentId)
      .pipe(takeUntil(this.componentIsDestroyed));

    this.loadingService.showLoaderUntilCompleted(oneClickPayResult$)
      .subscribe((result) => {
        if (result) {
          this.success();
        } else {
          this.fail();
        }
      });
  }

  useExistingCard() {
    this.isExistingCardActive = !this.isExistingCardActive;
    if (this.isExistingCardActive) {
      this.isNewCardActive = false;
    }
  }

  useNewCard() {
    this.isNewCardActive = !this.isNewCardActive;
    if (this.isNewCardActive) {
      this.isExistingCardActive = false;
    }
  }

  isPaymentMethodTransaction(transaction: PaymentTransaction) {
    return transaction.amount === 1
      && (transaction.description === 'Edit payment method'
        || transaction.description === 'Add payment method');
  }

  getSubscriptionChangingMessage(
    recalculationInfo: Observable<SubscriptionRecalculationInfo>,
  ): Observable<string> {
    return recalculationInfo.pipe(
      map((info) => {
        if (info) {
          return this.translationService.t(
            'settings.billing.subscription_payment.changing_message',
            {
              previous_unused_days: info.previousUnusedDays,
              previous_subscription_plan_name: info.previousSubscriptionPlan
                .name,
              new_additional_days: info.newAdditionalDays,
              new_subscription_plan_name: info.newSubscriptionPlan.name,
            },
          );
        }

        return null;
      }),
    );
  }

  ngOnDestroy(): void {
    this.componentIsDestroyed.next(true);
    this.componentIsDestroyed.unsubscribe();
  }

  private formDisclaimer(paymentTransaction$: Observable<PaymentTransaction>) {
    this.disclaimer$ = paymentTransaction$.pipe(
      map((paymentTransaction) => {
        if (paymentTransaction.subscription) {
          return this.translationService.t(
            'payment_page.disclaimer',
            {
              description: paymentTransaction.description,
              amount: `${paymentTransaction.amount} ${paymentTransaction.currency.code}`,
              subscription_plan: this.translationUtilService
                .getSubscriptionPlanDurationText(
                  paymentTransaction.subscription.subscriptionPlan,
                ),
            },
          );
        }

        return null;
      }),
    );
  }

  private formTransactionDetails(
    paymentTransaction$: Observable<PaymentTransaction>,
  ) {
    this.transactionDetails$ = paymentTransaction$.pipe(
      map((transaction) => {
        if (this.isPaymentMethodTransaction(transaction)) {
          return this.translationService.t(
            'payment_page.update_payment_method.warning',
            {
              amount: `${this.getCurrencySymbol(transaction.currency.code)}${transaction.amount}`,
            },
          );
        }

        return null;
      }),
    );
  }

  private formShowCardManagingBlock(
    paymentTransaction$: Observable<PaymentTransaction>,
    companyPaymentCard$: Observable<UserCard>,
  ) {
    this.showCardManagingBlock$ = combineLatest(
      [
        paymentTransaction$,
        companyPaymentCard$,
      ],
      (paymentTransaction, paymentCard) => {
        const showManagingBlock = paymentCard
        && !this.isPaymentMethodTransaction(paymentTransaction);

        if (showManagingBlock && !this.isNewCardActive) {
          this.isExistingCardActive = true;
        }

        return showManagingBlock;
      },
    );
  }

  moveToPaymentMethod() {
    if (this.formGroup.invalid) {
      Object.values(this.formGroup.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });

      return;
    }

    this.loadingService.loadingOn();
    this.billingDetailService.createOrUpdateBillingDetails(
      this.formGroup,
      this.billingDetails,
    ).pipe(
      catchError(() => {
        this.nzMessageService.error(
          this.translationService.t('settings.billing.details.update_failed'),
        );

        this.loadingService.loadingOff();

        return of([]);
      }),
    ).subscribe(() => {
      this.hasAllBillingDetails = true;
      this.currentStepIndex = PAYMENT_METHOD_STEP_INDEX;
      this.loadingService.loadingOff();
    });
  }

  onIndexChange(index: number) {
    if (index === PAYMENT_METHOD_STEP_INDEX && !this.hasAllBillingDetails) {
      this.currentStepIndex = INITIAL_STEP_INDEX;

      return;
    }

    this.currentStepIndex = index;
  }

  setFormGroup(formGroup: FormGroup) {
    this.formGroup = formGroup;
  }

  setBillingDetails(billingDetails: BillingDetail[]) {
    this.billingDetails = billingDetails;
  }
}
